<template>
  <div class="wizard-settings col" style="height: 0px; overflow: hidden">
    <div class="settings-controls">
      <button
        class="cfg-btn btn-preview"
        form="app-params-form"
        autocomplete="off"
        onclick="applySettings();"
      >
        Apply settings
      </button>
    </div>
    <div class="inner">
      <form id="app-params-form" autocomplete="off" onsubmit="return false;">
        <table id="params">
          <caption>
            General settings
          </caption>
          <tr>
            <td>zoomMode</td>
            <td>
              <select name="zoomMode">
                <option value="zoom" selected="selected">zoom</option>
                <option value="magnifier">magnifier</option>
                <option value="preview">preview</option>
                <option value="off">off</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>zoomOn</td>
            <td>
              <select name="zoomOn">
                <option value="hover" selected="selected">hover</option>
                <option value="click">click</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>zoomPosition</td>
            <td>
              <select name="zoomPosition">
                <option value="right" selected="selected">right</option>
                <option value="left">left</option>
                <option value="top">top</option>
                <option value="bottom">bottom</option>
                <option value="inner">inner</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>zoomWidth</td>
            <td><input type="text" name="zoomWidth" value="auto" /></td>
          </tr>
          <tr>
            <td>zoomHeight</td>
            <td><input type="text" name="zoomHeight" value="auto" /></td>
          </tr>
          <tr>
            <td>zoomDistance</td>
            <td><input type="text" name="zoomDistance" value="15" /></td>
          </tr>
          <tr>
            <td>zoomCaption</td>
            <td>
              <select name="zoomCaption">
                <option value="off" selected="selected">off</option>
                <option value="bottom">bottom</option>
                <option value="top">top</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>expand</td>
            <td>
              <select name="expand">
                <option selected="selected" value="window">window</option>
                <option value="fullscreen">fullscreen</option>
                <option value="off">off</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>expandZoomMode</td>
            <td>
              <select name="expandZoomMode">
                <option selected="selected" value="zoom">zoom</option>
                <option value="magnifier">magnifier</option>
                <option value="off">off</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>expandZoomOn</td>
            <td>
              <select name="expandZoomOn">
                <option selected="selected" value="click">click</option>
                <option value="always">always</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>expandCaption</td>
            <td>
              <select name="expandCaption">
                <option selected="selected" value="true">true</option>
                <option value="false">false</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>closeOnClickOutside</td>
            <td>
              <select name="closeOnClickOutside">
                <option selected="selected" value="true">true</option>
                <option value="false">false</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>history</td>
            <td>
              <select name="history">
                <option selected="selected" value="true">true</option>
                <option value="false">false</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>hint</td>
            <td>
              <select name="hint">
                <option value="once" selected="selected">once</option>
                <option value="always">always</option>
                <option value="off">off</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>smoothing</td>
            <td>
              <select name="smoothing">
                <option value="true" selected="selected">true</option>
                <option value="false">false</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>variableZoom</td>
            <td>
              <select name="variableZoom">
                <option value="false" selected="selected">false</option>
                <option value="true">true</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>lazyZoom</td>
            <td>
              <select name="lazyZoom">
                <option value="false" selected="selected">false</option>
                <option value="true">true</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>upscale</td>
            <td>
              <select name="upscale">
                <option value="true" selected="selected">true</option>
                <option value="false">false</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>rightClick</td>
            <td>
              <select name="rightClick">
                <option value="false" selected="selected">false</option>
                <option value="true">true</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>transitionEffect</td>
            <td>
              <select name="transitionEffect">
                <option value="true" selected="selected">true</option>
                <option value="false">false</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>selectorTrigger</td>
            <td>
              <select name="selectorTrigger">
                <option value="click" selected="selected">click</option>
                <option value="hover">hover</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>cssClass</td>
            <td><input type="text" name="cssClass" value="" /></td>
          </tr>

          <tr>
            <td>textHoverZoomHint</td>
            <td>
              <input
                type="text"
                name="textHoverZoomHint"
                value="Hover to zoom"
              />
            </td>
          </tr>
          <tr>
            <td>textClickZoomHint</td>
            <td>
              <input
                type="text"
                name="textClickZoomHint"
                value="Click to zoom"
              />
            </td>
          </tr>
          <tr>
            <td>textExpandHint</td>
            <td>
              <input
                type="text"
                name="textExpandHint"
                value="Click to expand"
              />
            </td>
          </tr>
          <tr>
            <td>textBtnClose</td>
            <td><input type="text" name="textBtnClose" value="Close" /></td>
          </tr>
          <tr>
            <td>textBtnNext</td>
            <td><input type="text" name="textBtnNext" value="Next" /></td>
          </tr>
          <tr>
            <td>textBtnPrev</td>
            <td><input type="text" name="textBtnPrev" value="Previous" /></td>
          </tr>
        </table>

        <table id="mobile-params">
          <caption>
            Special settings for mobile
          </caption>
          <tr>
            <td>zoomMode</td>
            <td>
              <select name="zoomMode">
                <option value="zoom" selected="selected">zoom</option>
                <option value="magnifier">magnifier</option>
                <option value="off">off</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>textHoverZoomHint</td>
            <td>
              <input
                type="text"
                name="textHoverZoomHint"
                value="Touch to zoom"
              />
            </td>
          </tr>
          <tr>
            <td>textClickZoomHint</td>
            <td>
              <input
                type="text"
                name="textClickZoomHint"
                value="Double tap or pinch to zoom"
              />
            </td>
          </tr>
          <tr>
            <td>textExpandHint</td>
            <td>
              <input
                type="text"
                name="textExpandHint"
                value="Tap or pinch to expand"
              />
            </td>
          </tr>
        </table>
      </form>
    </div>
    <div class="footer">
      &copy; <a href="https://www.magictoolbox.com">Magic Toolbox</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>