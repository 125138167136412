<template>
  <div class="api-controls" style="height: 0px; overflow: hidden">
    <button class="cfg-btn" onclick="MagicZoom.start('Zoom-1')">Start</button>
    <button class="cfg-btn" onclick="MagicZoom.stop('Zoom-1')">Stop</button>
    <button class="cfg-btn" onclick="MagicZoom.refresh('Zoom-1')">
      Refresh
    </button>
    <button class="cfg-btn" onclick="MagicZoom.prev('Zoom-1')">Prev</button>
    <button class="cfg-btn" onclick="MagicZoom.next('Zoom-1')">Next</button>
    <button class="cfg-btn" onclick="MagicZoom.zoomIn('Zoom-1')">
      Zoom In
    </button>
    <button class="cfg-btn" onclick="MagicZoom.zoomOut('Zoom-1')">
      Zoom Out
    </button>
    <button
      class="cfg-btn"
      onclick="MagicZoom.expand('Zoom-1')"
      title="Plus version only."
    >
      Expand
    </button>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>