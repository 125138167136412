<template>
  <div>
    <section class="content">
      <article class="demo-area">
        <img
          class="demo-trigger"
          :src="imgxSmall"
          :data-zoom="imgLarge"
        />
        <div class="detail">

        </div>
      </article>
    </section>
    <!-- <div class="preview col">
      <div class="app-figure" id="zoom-fig">
        <a
          id="Zoom-1"
          class="MagicZoom"
          title="Show your product in stunning detail with Magic Zoom Plus."
          :href="imgSmall"
          :data-zoom-image-2x="imgLarge"
          :data-image-2x="imgSmall"
        >
          <img
            :src="imgSmall"
            :srcset="imgSmall"
            alt=""
          />
        </a>
        <div class="selectors">
          <a
            data-zoom-id="Zoom-1"
            :href="imgSmall"
            :data-image="imgxSmall"
            :data-zoom-image-2x="imgLarge"
            :data-image-2x="imgSmall"
          >
            <img
              :srcset="imgxSmall"
              :src="imgxSmall"
            />
          </a>
        </div>
      </div>
    </div> -->
    <button @click="initDrift">asdfasf</button>
    <button @click="changeImg">kk</button>
    <Ztwo />
    <Zone />
    <Zthree />
  </div>
</template>

<script>
import Zone from "../components/frontend/zoombox/Zprt1.vue";
import Ztwo from "../components/frontend/zoombox/Zprt2.vue";
import Zthree from "../components/frontend/zoombox/Zprt3.vue";
import Drift from "drift-zoom";
export default {
  components: {
    Zone,
    Ztwo,
    Zthree,
  },
  data() {
    return {
      imgLarge:
        "https://images.miah.shop/product/m_thumb/Saree_Tant_1055_9385_1.jpg",
      imgSmall:
        "https://images.miah.shop/product/m_thumb/Saree_Tant_1055_9385_1.jpg",
      imgxSmall:
        "https://images.miah.shop/product/s_thumb/Saree_Tant_1055_9385_1.jpg",
    };
  },
  mounted() {
    this.initDrift()
  },
  methods: {
    changeImg() {
      this.imgLarge =
        "https://images.miah.shop/product/comon-new-36-piece-web-formate-011.jpg";
      this.imgxSmall =
        "https://images.miah.shop/product/s_thumb/comon-new-36-piece-web-formate-011.jpg";
      console.log("changed");
    },
    initDrift() {
      var demoTrigger = document.querySelector(".demo-trigger");
      var paneContainer = document.querySelector(".detail");

      new Drift(demoTrigger, {
        paneContainer: paneContainer,
        inlinePane: false,
      });
      console.log("drft");
    },
  },
};
</script>

<style>

picture {
  text-align: center;
}

.demo-area {
  background: #fafafa;
  border-radius: 8px;
  padding: 20px;
}
.demo-area section {
  padding-top: 0;
}

.demo-trigger {
  display: inline-block;
  width: 30%;
  float: left;
}

.detail {
  position: relative;
  width: 65%;
  margin-left: 5%;
  float: left;
  height: 300px;
}
</style>