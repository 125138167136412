<template>
  <div class="app-code-sample" style="height:0px; overflow:hidden">
    <input
      type="radio"
      name="code-sample-nav"
      id="code-sample-nav-1"
      checked="checked"
    /><label for="code-sample-nav-1">Global settings</label
    ><input
      type="radio"
      name="code-sample-nav"
      id="code-sample-nav-2"
    /><label for="code-sample-nav-2">Inline settings</label>
    <div class="app-code-holder">
      <div>
        <pre class="prettyprint" id="app-code-sample-script">
                &lt;script&gt;
                var mzOptions = {};
                &lt;/script&gt;
            </pre
        >
        <button
          class="cfg-btn cfg-btn-copy"
          autocomplete="off"
          onclick="copyToClipboard('app-code-sample-script')"
        >
          Copy
        </button>
      </div>
      <div>
        <pre class="prettyprint" id="app-code-sample-inline">
                &lt;a class="MagicZoom" data-options=""&gt;
            </pre
        >
        <button
          class="cfg-btn cfg-btn-copy"
          autocomplete="off"
          onclick="copyToClipboard('app-code-sample-inline')"
        >
          Copy
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>